import { where } from 'firelordjs';

import { compact, union } from 'lodash';

import { getUserCompanyId } from '@/lib/firebase/db/helpers/userHelpers';
import { UserDoc } from '@/lib/firebase/db/metaTypes';

import { ISD_DISPATCH_COMPANY_ID, ISHEEPDOG_COMPANY_ID } from '@/lib/env';

export * from './userHelpers';
export * from './jobHelpers';
export * from './companyBrokerHelpers';
export * from './dispatchScheduleHelpers';
export * from './operatorTruckHelpers';
export * from './taskHelpers';
export * from './jobAssignmentsHelpers';

/**
 * Constructs a Firestore query constraint for filtering documents based on reader companies.
 * @function
 * @param {UserDoc} [userDoc] - The user document containing reader companies information.
 * @returns {QueryConstraint|null} A Firestore query constraint for filtering documents based on reader companies, or null if userDoc is not provided.
 */
export function getReaderCompaniesConstraint(userDoc?: UserDoc) {
  return (
    !!userDoc &&
    where('readerCompanies', 'array-contains', getUserCompanyId(userDoc))
  );
}

/**
 * Constructs an array of strings to be used as value for readerCompanies field in read-protected documents.
 * @function
 * @param {string[]} [readerCompanies] - The array of custom companies ids to be included in reader companies array.
 * @returns {string[]} Array containing default reader companies and the ones previously provided.
 */
export function getReaderCompaniesArray(readerCompanies: string[]) {
  return compact(
    union([...readerCompanies, ISD_DISPATCH_COMPANY_ID, ISHEEPDOG_COMPANY_ID])
  );
}
