// general purpose utility functions

/**
 * Retrieves the global object, depending on the runtime environment.
 * @function
 * @returns {Object} The global object (window in browsers, global in Node.js).
 */
export function getGlobal() {
  if (typeof window !== 'undefined') return window;
  return global;
}

/**
 * Replaces parameters in a route string with corresponding values from a given object.
 * @function
 * @param {string} route - The route string containing parameters to replace.
 * @param {Record<string, string>} params - The object containing parameter-value pairs.
 * @returns {string} The route string with parameters replaced by their values.
 * @example
 * const route = '/user/:userId/profile';
 * const params = { userId: '123' };
 * const replacedRoute = replaceParamsInRoute(route, params);
 * // Returns '/user/123/profile'
 */
export function replaceParamsInRoute(
  route: string,
  params: Record<string, string>
) {
  const PATH_DELIMITER = '/';
  const routeSegments = route.split(PATH_DELIMITER);
  const newSegments = routeSegments.map((segment) => {
    const parameter = segment.substring(1);
    const value = params[parameter];
    return value || segment;
  });

  return newSegments.join(PATH_DELIMITER);
}

/**
 * Checks if the current environment is localhost.
 * This function determines whether the script is running on a localhost environment by examining the hostname
 * of the global `location` object. It considers the environment as localhost if the hostname is 'localhost', '[::1]'
 * (the IPv6 localhost address), or matches the IPv4 localhost address range (127.0.0.1/8). This utility is useful
 * for conditionally executing code that should only run in a development environment, such as logging debug information
 * or connecting to development APIs.
 *
 * @returns {boolean} Returns `true` if the current environment's hostname indicates it is localhost, otherwise `false`.
 */
export function isLocalhost() {
  const windowObj = getGlobal();
  if (!windowObj || !windowObj.location) return false;
  return Boolean(
    windowObj.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      windowObj.location.hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      windowObj.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );
}
