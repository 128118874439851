// Helper functions for JobAssignments collection
import { getUserCompanyId } from '@/lib/firebase/db/helpers/userHelpers';
import { JobAssignmentDoc, UserDoc } from '@/lib/firebase/db/metaTypes';

import { isAssigner } from '@/lib/helpers/userRoles';

/**
 * Determines if the user is the assigner for a given job assignment.
 * This function first checks if the user has the role of an assigner. If so, it then checks whether the user's company
 * is the receiver of the job assignment or if the user's company is part of the job assignment's assignment history.
 * This ensures that only users who are directly involved in the job assignment, either as the current receiver or as a
 * previous assigner, can be recognized as assigners. This function is useful for access control, ensuring that actions
 * related to a job assignment can only be performed by relevant parties.
 *
 * @param {UserDoc} userDoc - The document representing the user, containing user roles and company affiliation.
 * @param {JobAssignmentDoc} jobAssignmentDoc - The document representing the job assignment, containing the receiver and assignment history.
 * @returns {boolean} Returns `true` if the user is the assigner for the job assignment, otherwise `false`.
 */
export function getIsUserJobAssignmentAssigner(
  userDoc: UserDoc,
  jobAssignmentDoc: JobAssignmentDoc
): boolean {
  if (!isAssigner(userDoc)) {
    return false;
  }
  const userCompanyId = getUserCompanyId(userDoc);
  // Check if user company is the receiver of the Job Assignment
  if (userCompanyId === jobAssignmentDoc.get('receiver.company.id'))
    return true;
  // Check if user company is the assigner of the Job Assignment
  if (userCompanyId === jobAssignmentDoc.get('assigner.company.id'))
    return true;
  // Check if user company is part of the Job Assignment's assignment history
  return !!jobAssignmentDoc
    .get('assignmentHistory')
    ?.some(({ assignerCompanyId }) => assignerCompanyId === userCompanyId);
}
